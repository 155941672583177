import React             from 'react';
import { graphql, Link } from 'gatsby';
import Img               from 'gatsby-image';
import { Flex, Box }     from '@chakra-ui/react';
import { injectIntl }    from 'react-intl';

import HeaderImage    from '@interness/web-core/src/components/media/HeaderImage/HeaderImage';
import Wrapper        from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Spacer         from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Heading        from '@interness/web-core/src/components/text/Heading/Heading';
import ContactSidebar from '@interness/web-core/src/components/structure/ContactSidebar/ContactSidebar';
import SEO            from '@interness/web-core/src/components/modules/SEO/SEO';
import ContactForm    from '@interness/web-core/src/components/modules/ContactForm/ContactForm';
import ContactMap     from '@interness/web-core/src/components/media/ContactMap';

const ContactPage = props => {
  const intl = props.intl.formatMessage;

  return (
    <>
      <SEO title={intl({ id: 'pages.contact.seo_title' })}/>
      <HeaderImage>
        <Img fluid={props.data.headerImage.childImageSharp.fluid}/>
      </HeaderImage>
      <Wrapper>
        <Heading>{intl({ id: 'pages.contact.heading' })}</Heading>
        <Spacer height={20}/>

        <Flex flexWrap='wrap'>
          <Box width={['100%', '50%', '50%', '50%']}>
            <h3>{intl({ id: 'pages.contact.contactInfo' })}</h3>
            <ContactSidebar/>
            <Spacer height={20} />
          </Box>
          <Box width={['100%', '50%', '50%', '50%']}>
            <ContactForm />
          </Box>
        </Flex>
        <div>
          <p>
            {intl({ id: 'pages.contact.privacyApproval1' })}
            <Link
              to={intl({ id: 'pages.contact.privacyLink' })}>{intl({ id: 'pages.contact.privacyLinkName' })}</Link>
            {intl({ id: 'pages.contact.privacyApproval2' })}
          </p>
        </div>
        <Spacer/>
      </Wrapper>
      <ContactMap />
    </>
  )
};

export default injectIntl(ContactPage);

export const query = graphql`
  query {
    headerImage: file(relativePath: {eq: "headers/contact.jpg"}) {
      name
      childImageSharp {
        fluid(maxWidth: 1980, maxHeight: 700, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;